import { type FetchError } from 'ofetch';
import { RelationFactory } from '~/models/factories/RelationFactory';
import { type Relation } from '~/models/Relation';
import BaseApiService from '~/services/api/BaseApiService';
import { type RelationResponse } from '~/types/RelationResponse';
import { type WriteRelationProfileDto } from '~/types/RelationProfile';

export default class RelationService extends BaseApiService {
    private basePath = 'relation';

    /**
     * @description Get the relation based on the token of Extranet user.
     * @returns {Promise<Relation>} Promise with an array List models as payload
     */
    async fetchRelation(): Promise<Relation> {
        try {
            const response = await this.baseGet<RelationResponse>(
                `${this.createPath(this.basePath)}`,
            );

            return (new RelationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Update the relation based on the token of Extranet user.
     * @param {WriteRelationProfileDto} data Form data to put
     * @returns {Promise<null>} Api response
     */
    updateProfile(data: WriteRelationProfileDto): Promise<null> {
        try {
            return this.basePut<null>(
                `${this.createPath(this.basePath)}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
