import {
    SET_ACTIVITY,
    SET_CERTIFICATE,
    SET_COMPANY,
    SET_PERSONAL_MOVEMENT,
    SET_RELATION,
    SET_RELATION_SURVEY_LIST,
} from './-mutation-types';
import { useExtranetStore } from '~/stores/extranet';
import ActivityService from '~/services/api/extranet/ActivityService';
import CompanyService from '~/services/api/extranet/CompanyService';
import RelationService from '~/services/api/extranet/RelationService';
import CertificateService from '~/services/api/extranet/CertificateService';
import PersonalMovementService from '~/services/api/extranet/PersonalMovementService';
import ExtranetSurveyListService from '~/services/api/extranet/ExtranetSurveyListService';
import type { ExtranetActivity } from '~/models/ExtranetActivity';
import type { Certificate } from '~/models/Certificate';
import type { ExtranetCompany } from '~/models/ExtranetCompany';
import type { RelationMovement } from '~/models/RelationMovement';
import type { Relation } from '~/models/Relation';
import type { ExtranetRelationSurveyList } from '~/models/ExtranetRelationSurveyList';

const Actions = {
    async fetchActivity(activityId: number) {
        const extranetStore = useExtranetStore();
        const activityService = new ActivityService();
        const response = await activityService.fetchActivity(activityId);

        extranetStore[SET_ACTIVITY](response);
    },
    async fetchCertificate(certificateId: number) {
        const extranetStore = useExtranetStore();
        const certificateService = new CertificateService();
        const response = await certificateService.fetchCertificate(certificateId);

        extranetStore[SET_CERTIFICATE](response);
    },
    async fetchCompany(companyId: number) {
        const extranetStore = useExtranetStore();
        const companyService = new CompanyService();
        const response = await companyService.fetchCompany(companyId);

        extranetStore[SET_COMPANY](response);
    },
    async fetchPersonalMovement() {
        const extranetStore = useExtranetStore();
        const movementService = new PersonalMovementService();
        const response = await movementService.fetchPersonalMovement();

        extranetStore[SET_PERSONAL_MOVEMENT](response);
    },
    async fetchRelation() {
        const extranetStore = useExtranetStore();
        const relationService = new RelationService();
        const response = await relationService.fetchRelation();

        extranetStore[SET_RELATION](response);
    },
    async fetchRelationSurveyList(uuid: string) {
        const extranetStore = useExtranetStore();

        const extranetSurveyListService = new ExtranetSurveyListService();
        const response = await extranetSurveyListService.fetchAnsweredSurveylist(uuid);

        extranetStore[SET_RELATION_SURVEY_LIST](response);
    },
    setActivity(activity: ExtranetActivity) {
        const extranetStore = useExtranetStore();
        extranetStore[SET_ACTIVITY](activity);
    },
    setCertificate(certificate: Certificate) {
        const extranetStore = useExtranetStore();
        extranetStore[SET_CERTIFICATE](certificate);
    },
    setCompany(company: ExtranetCompany) {
        const extranetStore = useExtranetStore();
        extranetStore[SET_COMPANY](company);
    },
    setPersonalMovement(relation: RelationMovement) {
        const extranetStore = useExtranetStore();
        extranetStore[SET_PERSONAL_MOVEMENT](relation);
    },
    setRelation(relation: Relation) {
        const extranetStore = useExtranetStore();
        extranetStore[SET_RELATION](relation);
    },
    setRelationSurveyList(relationSurveyList: ExtranetRelationSurveyList) {
        const extranetStore = useExtranetStore();
        extranetStore[SET_RELATION_SURVEY_LIST](relationSurveyList);
    },
};

export default Actions;
