import {
    GET_ACTIVITY,
    GET_CERTIFICATE,
    GET_COMPANY,
    GET_PERSONAL_MOVEMENT,
    GET_RELATION,
    GET_RELATION_SURVEY_LIST,
} from './-getter-types';
import { useExtranetStore } from '~/stores/extranet';
import { type Certificate } from '~/models/Certificate';
import { type Relation } from '~/models/Relation';
import { type ExtranetActivity } from '~/models/ExtranetActivity';
import { type ExtranetCompany } from '~/models/ExtranetCompany';
import { type RelationMovement } from '~/models/RelationMovement';
import { type ExtranetRelationSurveyList } from '~/models/ExtranetRelationSurveyList';

const Getters = {
    [GET_ACTIVITY]: (): ExtranetActivity | null => {
        const extranetStore = useExtranetStore();

        return extranetStore.activity;
    },
    [GET_CERTIFICATE]: (): Certificate | null => {
        const extranetStore = useExtranetStore();

        return extranetStore.certificate;
    },
    [GET_COMPANY]: (): ExtranetCompany | null => {
        const extranetStore = useExtranetStore();

        return extranetStore.company;
    },
    [GET_PERSONAL_MOVEMENT]: (): RelationMovement | null => {
        const extranetStore = useExtranetStore();

        return extranetStore.personalMovement;
    },
    [GET_RELATION]: (): Relation | null => {
        const extranetStore = useExtranetStore();

        return extranetStore.relation;
    },
    [GET_RELATION_SURVEY_LIST]: (): ExtranetRelationSurveyList | null => {
        const extranetStore = useExtranetStore();

        return extranetStore.relationSurveyList;
    },
};

export default Getters;
