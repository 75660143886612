import { type ExtranetActivity } from '~/models/ExtranetActivity';
import { type ExtranetCompany } from '~/models/ExtranetCompany';
import { type Certificate } from '~/models/Certificate';
import { type Relation } from '~/models/Relation';
import { type RelationMovement } from '~/models/RelationMovement';
import { type ExtranetRelationSurveyList } from '~/models/ExtranetRelationSurveyList';

export type RelationState = {
    activity: ExtranetActivity | null;
    certificate: Certificate | null;
    relation: Relation | null;
    company: ExtranetCompany | null;
    personalMovement: RelationMovement | null;
    relationSurveyList: ExtranetRelationSurveyList | null;
}

export const defaultStateData = (): RelationState => ({
    activity: null,
    certificate: null,
    company: null,
    personalMovement: null,
    relation: null,
    relationSurveyList: null,
});

export const DefaultState = (): RelationState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
