import {
    SET_ACTIVITY,
    SET_CERTIFICATE,
    SET_COMPANY,
    SET_PERSONAL_MOVEMENT,
    SET_RELATION,
    SET_RELATION_SURVEY_LIST,
} from './-mutation-types';
import { useExtranetStore } from '~/stores/extranet';
import { type Certificate } from '~/models/Certificate';
import { type Relation } from '~/models/Relation';
import { type ExtranetActivity } from '~/models/ExtranetActivity';
import { type ExtranetCompany } from '~/models/ExtranetCompany';
import { type RelationMovement } from '~/models/RelationMovement';
import { type ExtranetRelationSurveyList } from '~/models/ExtranetRelationSurveyList';

const Mutations = {
    [SET_ACTIVITY](payload: ExtranetActivity | null) {
        const extranetStore = useExtranetStore();
        extranetStore.activity = payload;
    },
    [SET_CERTIFICATE](payload: Certificate | null) {
        const extranetStore = useExtranetStore();
        extranetStore.certificate = payload;
    },
    [SET_COMPANY](payload: ExtranetCompany | null) {
        const extranetStore = useExtranetStore();
        extranetStore.company = payload;
    },
    [SET_PERSONAL_MOVEMENT](payload: RelationMovement | null) {
        const extranetStore = useExtranetStore();
        extranetStore.personalMovement = payload;
    },
    [SET_RELATION](payload: Relation | null) {
        const extranetStore = useExtranetStore();
        extranetStore.relation = payload;
    },
    [SET_RELATION_SURVEY_LIST](payload: ExtranetRelationSurveyList | null) {
        const extranetStore = useExtranetStore();
        extranetStore.relationSurveyList = payload;
    },
};

export default Mutations;
